import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const Installer = ({
  name,
  desc,
  services,
  webSite,
  salesEmail,
  salesForm,
  salesPhone,
  supportEmail,
  supportForm,
  supportPhone,
  address,
  manufacturers,
}) => (
  <div className={styles.wrap}>
    <h2 className={styles.title}>{name}</h2>
    {desc && <p>{desc}</p>}
    {(webSite || address) && (
      <div>
        {webSite && (
          <p className={styles.info}>
            <strong>Web Site: </strong>
            <a href={webSite} target="_blank" rel="nofollow noreferrer">
              {webSite}
            </a>
          </p>
        )}
        {address && (
          <p className={styles.info}>
            <strong>Address: </strong>
            {address}
          </p>
        )}
      </div>
    )}
    {services?.length > 0 && (
      <>
        <h3>Services Offered</h3>
        <ul>
          {services.map((service, idx) => (
            <li key={idx}>{service}</li>
          ))}
        </ul>
      </>
    )}
    {manufacturers?.length > 0 && (
      <>
        <h3>Manufacturers</h3>
        <ul>
          {manufacturers.map((manufacturer, idx) => (
            <li key={idx}>{manufacturer}</li>
          ))}
        </ul>
      </>
    )}
    {(salesEmail || salesPhone || salesForm) && (
      <>
        <h3>How to Contact Sales</h3>
        {salesEmail && (
          <p className={styles.info}>
            <strong>Email: </strong>
            <a href={`mailto:${salesEmail}`}>{salesEmail}</a>
          </p>
        )}
        {salesForm && (
          <p className={styles.info}>
            <strong>Contact Form: </strong>
            <a href={salesForm} target="_blank" rel="nofollow noreferrer">
              Click to visit
            </a>
          </p>
        )}
        {salesPhone && (
          <p className={styles.info}>
            <strong>Phone: </strong>
            {salesPhone}
          </p>
        )}
      </>
    )}
    {(supportEmail || supportForm || supportPhone) && (
      <>
        <h3>How to Contact Support</h3>
        {supportEmail && (
          <p className={styles.info}>
            <strong>Email: </strong>
            <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
          </p>
        )}
        {supportForm && (
          <p className={styles.info}>
            <strong>Contact Form: </strong>
            <a href={supportForm} target="_blank" rel="nofollow noreferrer">
              Click to visit
            </a>
          </p>
        )}
        {supportPhone && (
          <p className={styles.info}>
            <strong>Phone: </strong>
            {supportPhone}
          </p>
        )}
      </>
    )}
  </div>
);

Installer.defaultProps = {
  desc: null,
  services: null,
  webSite: null,
  salesEmail: null,
  salesForm: null,
  salesPhone: null,
  supportEmail: null,
  supportForm: null,
  supportPhone: null,
  address: null,
  manufacturers: null,
};

Installer.propTypes = {
  name: PropTypes.string.isRequired,
  desc: PropTypes.string,
  webSite: PropTypes.string,
  salesEmail: PropTypes.string,
  salesForm: PropTypes.string,
  salesPhone: PropTypes.string,
  supportEmail: PropTypes.string,
  supportForm: PropTypes.string,
  supportPhone: PropTypes.string,
  address: PropTypes.string,
  services: PropTypes.arrayOf(PropTypes.string),
  manufacturers: PropTypes.arrayOf(PropTypes.string),
};

export default Installer;
