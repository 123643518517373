/* eslint-disable react/prop-types */
import React from 'react';
import LayoutMain from 'layouts/layout-main';
import Hero from 'components/pages/security-installers/hero';
import Installers from 'components/pages/security-installers/installers';
import HubspotFormWrapper from 'components/lazy-blocks/hubspot-form-wrapper';
import CategoryImage from 'components/pages/security-installers/category-image';
import { categoriesData } from 'utils/security-installers-categories';

import { FORM_IDS } from '../constants/form';

const SecurityInstallers = ({ pageContext, location }) => {
  const { installers, market, category, date } = pageContext;
  const categoryTitle = categoriesData[category]?.title || category;
  return (
    <LayoutMain
      seo={{
        title: `Best ${categoryTitle} in ${market}`,
        metaDesc: `Please review our list of best ${categoryTitle.toLowerCase()} in ${market}. Compare various local installation companies and their services, installation expertise and service region for ${categoryTitle.toLowerCase()}.`,
        date: {
          modifiedAt: date,
        },
      }}
      footerTheme="with-border"
      withBanner={false}
      withBottomOffset
      pageContext={pageContext}
      location={location}
    >
      <Hero title={`Best ${categoryTitle} in ${market}`} date={new Date(date).toDateString()} />
      <CategoryImage name={category} />
      <Installers installers={installers} />
      <HubspotFormWrapper
        title="Get a Swiftlane Quote"
        description="Tell us about your building and we will help you find the best solution"
        formId={FORM_IDS.REQUEST_A_QUOTE}
      />
    </LayoutMain>
  );
};

export default SecurityInstallers;
