import React from 'react';
import PropTypes from 'prop-types';
import { categoriesData } from 'utils/security-installers-categories';

import styles from './styles.module.scss';

const CategoryImage = ({ name }) => {
  const img = (categoriesData[name] || {}).image;
  if (!img) return null;
  return (
    <div className={styles.imgBox}>
      <img src={img} alt={name} />
    </div>
  );
};

CategoryImage.propTypes = {
  name: PropTypes.string.isRequired,
};

export default CategoryImage;
