import fireAlarm from 'images/installers/fire-alarm.jpeg';
import securityGuards from 'images/installers/security-guards.jpeg';
import accessControlSystems from 'images/installers/Access-Control-Systems.png';
import officeSecurity from 'images/installers/Office-Security.jpg';
import apartmentMultifamilySecurity from 'images/installers/Apartment-Multifamily-Security.png';
import doorHardware from 'images/installers/Door-hardware.jpeg';
import homeSecurity from 'images/installers/Home-Security.jpg';
import cloudBasedAccessControl from 'images/installers/Cloud-based-access-control.jpg';
import burglarAlarms from 'images/installers/Burglar-Alarms.jpeg';
import commercialSecurity from 'images/installers/Commercial-Security.jpeg';
import intercomSystems from 'images/installers/Intercom-systems.png';
import locksmith from 'images/installers/Locksmith.jpeg';
import amazonKeyBusiness from 'images/installers/Amazon-Key-for-Business.jpeg';
import CCTVCameraInstallation from 'images/installers/CCTV-Camera-Installation.jpeg';
import remoteMonitoring from 'images/installers/Remote-Monitoring.jpeg';

// eslint-disable-next-line import/prefer-default-export
export const categoriesData = {
  'Fire Alarms & Safety': {
    title: 'Fire Alarms & Safety system installers',
    image: fireAlarm,
  },
  'Security Guards': {
    title: 'Remote Security Guards and Monitoring',
    image: securityGuards,
  },
  'Access Control Systems': {
    title: 'Access Control Systems installers',
    image: accessControlSystems,
  },
  'Office Security': {
    title: 'Office Security companies',
    image: officeSecurity,
  },
  'Apartment/Multifamily Security': {
    title: 'Apartment/Multifamily Security solutions',
    image: apartmentMultifamilySecurity,
  },
  'Door hardware': {
    title: 'Door hardware installers',
    image: doorHardware,
  },
  'Home Security': {
    title: 'Home Security companies',
    image: homeSecurity,
  },
  'Cloud based access control': {
    title: 'Cloud based access control system installers',
    image: cloudBasedAccessControl,
  },
  'Burglar Alarms': {
    title: 'Burglar Alarms companies',
    image: burglarAlarms,
  },
  'Commercial Security': {
    title: 'Commercial Security companies',
    image: commercialSecurity,
  },
  'Intercom Systems': {
    title: 'Intercom Systems installers',
    image: intercomSystems,
  },
  Locksmith: {
    title: 'Locksmiths',
    image: locksmith,
  },
  'Video intercom Systems': {
    title: 'Video intercom Systems installers',
    image: intercomSystems,
  },
  'Amazon Key for Business': {
    title: 'Amazon Key for Business installers',
    image: amazonKeyBusiness,
  },
  'CCTV Camera Installation': {
    title: 'CCTV Camera Installers',
    image: CCTVCameraInstallation,
  },
  'Remote Monitoring': {
    title: 'Remote Monitoring and Virtual doormen services',
    image: remoteMonitoring,
  },
};
