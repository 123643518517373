import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { StickyContainer } from 'react-sticky';
import TableOfContent from 'components/shared/content/table-of-content';
import { toDashedStr } from 'utils/security-installers';
import styles from './styles.module.scss';
import Installer from '../installer';

const Installers = ({ installers }) => {
  const links = useMemo(
    () =>
      installers.map((installer) => ({
        linkText: installer.installer_name,
        url: `#${toDashedStr(installer.installer_name)}`,
      })),
    []
  );
  return (
    <div className={styles.wrapper}>
      <StickyContainer className="container">
        {installers?.length > 1 && (
          <TableOfContent
            useCustomTitleOnly
            title="Table of contents"
            contentClassName="container"
            extraLinks={links}
          />
        )}
        <div className="container">
          <div className={styles.content}>
            {installers?.map((installer, key) => (
              <div
                className={styles.installer}
                key={key}
                id={toDashedStr(installer.installer_name)}
              >
                <Installer
                  name={installer.installer_name}
                  desc={installer.business_desc}
                  webSite={installer.website_link}
                  salesEmail={installer.sales_email}
                  salesForm={installer.sales_quote_form}
                  salesPhone={installer.sales_phone}
                  supportEmail={installer.support_email}
                  supportForm={installer.support_form}
                  supportPhone={installer.support_phone}
                  address={installer.complete_address}
                  services={installer.installer_categories}
                  manufacturers={installer.security_products}
                />
              </div>
            ))}
          </div>
        </div>
      </StickyContainer>
    </div>
  );
};

Installers.propTypes = {
  installers: PropTypes.arrayOf(
    PropTypes.shape({
      installer_name: PropTypes.string,
      business_desc: PropTypes.string,
      website_link: PropTypes.string,
      sales_email: PropTypes.string,
      sales_quote_form: PropTypes.string,
      sales_phone: PropTypes.string,
      support_email: PropTypes.string,
      support_phone: PropTypes.string,
      support_form: PropTypes.string,
      complete_address: PropTypes.string,
      security_products: PropTypes.arrayOf(PropTypes.string),
      installer_categories: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
};

export default Installers;
