import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const Hero = ({ title, date }) => (
  <div className={styles.wrapper}>
    <div className="container">
      <div className={styles.content}>
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.date}>
          <strong>Last Modified At: </strong>
          <span>{date}</span>
        </div>
      </div>
    </div>
  </div>
);

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

export default Hero;
