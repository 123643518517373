/* eslint-disable no-undef */

// Impact Conversion Pixel
// Read more: https://impact-helpdesk.freshdesk.com/support/solutions/articles/48001174916?accountType=ADVERTISER#trackcart-function-last-to-cart-crediting--0-4
const sendImpactEvent = (eventId, params) => {
  if (typeof ire !== 'undefined') {
    ire('trackConversion', eventId, params, {
      verifySiteDefinitionMatch: true,
    });

    console.log(`Impact sent parameters: ${JSON.stringify(params)}`);
  }
};

export default sendImpactEvent;
