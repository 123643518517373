const fetch = (...args) => import(`node-fetch`).then(({ default: fetch }) => fetch(...args));

const stateList = {
  Arizona: 'AZ',
  Alabama: 'AL',
  Alaska: 'AK',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
};

const apiUri = process.env.SECURITY_INSTALLERS_API_URI;
const apiKeyVal = process.env.SECURITY_INSTALLERS_API_KEY;

const getApi = (api, query) =>
  fetch(`${api}/?${query}`, {
    method: 'GET',
    headers: {
      apiKey: apiKeyVal,
    },
  }).then((response) => response.json());

const getMarkets = async () => {
  try {
    const { data } = await getApi(apiUri, 'res_data_type=markets');
    return data.markets;
  } catch (err) {
    return null;
    // throw new Error(err.message);
  }
};

const getMarketCategories = async (market) => {
  try {
    const { data } = await getApi(apiUri, `res_data_type=categories&market=${market}`);
    return data.categories;
  } catch (err) {
    return null;
    // throw new Error(err.message);
  }
};

const getInstallers = async (market, category) => {
  try {
    const { data } = await getApi(
      apiUri,
      `res_data_type=installers&market=${market}&installer_categories=${category}`
    );
    return data.installers;
  } catch (err) {
    return null;
    // throw new Error(err.message);
  }
};

const toDashedStr = (str) => str.trim().toLowerCase().replace(/ /g, '-').replace('.', '');

const sortByRating = (a, b) => {
  // Handle undefined values
  if (a.rating === undefined && b.rating === undefined) {
    return 0;
  }
  if (a.rating === undefined) {
    return 1;
  }
  if (b.rating === undefined) {
    return -1;
  }

  // Sort by rating
  return a.rating - b.rating;
};

module.exports = {
  stateList,
  getMarkets,
  getMarketCategories,
  getInstallers,
  toDashedStr,
  sortByRating,
};
